<template>
	<div class="animated fadeIn">
		<!-- Search Start -->
		<template v-if="isAdminOrCorporate">
			<b-alert
				show
				variant="secondary">
				<div class="row">
					<div class="col">
						<search
							ref="searchComp"
							v-model="distributor"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor', tree:'unilevel'}"
							:init-value="String(distributor)"
							return-key="id"
							class="mb-3"
							q="label" />
						<div class="row">
							<div class="col">
								<b-button
									:disabled="loading"
									variant="primary"
									class="btn mx-1"
									@click="changeDistributor()">
									{{ translate('search') }}
								</b-button>
								<b-button
									:disabled="loading"
									variant="secondary"
									class="btn mx-1"
									@click="clearDistributor()">
									{{ translate('clear') }}
								</b-button>
							</div>
						</div>
					</div>
				</div>
			</b-alert>
			<hr>
		</template>
		<!-- Search End -->
		<!-- Filters Start -->
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-sm">
						<search
							v-model="filters.id"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor', tree:'unilevel'}"
							class="mb-3"
							q="label"
							return-key="id" />
					</div>
				</div>
			</template>
		</data-filter>
		<!-- Filters End -->
		<b-row>
			<b-col>
				<div
					v-if="!loading"
					class="table-responsive">
					<table class="table table-hover table-striped text-nowrap">
						<thead>
							<tr>
								<th class="border-top-0">
									#
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('id')">
									{{ translate('user_id') }}
									<sort field="id" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('username')">
									{{ translate('username') }}
									<sort field="username" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('distance')">
									{{ translate('distance') }}
									<sort field="distance" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('highest_rank')">
									{{ translate('highest_rank') }}
									<sort field="highest_rank" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('rrc.sort_order')">
									{{ translate('rank_completed', {week: weeks.final}) }}
									<sort field="rrc.sort_order" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('rre.sort_order')">
									{{ translate('rank_estimated', {week: weeks.estimated}) }}
									<sort field="rre.sort_order" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('rrp.sort_order')">
									{{ translate('rank_progress', {week: weeks.in_progress}) }}
									<sort field="rrp.sort_order" />
								</th>
								<th class="border-top-0 text-center">
									{{ translate('options') }}
								</th>
							</tr>
						</thead>
						<tbody v-if="hasData">
							<tr
								v-for="(item, index) in data"
								:key="index">
								<td class="align-middle">
									{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
								</td>
								<td
									class="align-middle">
									<span
										v-if="isAdminOrCorporate"
										class="badge badge-primary pointer"
										@click="toDashboard({ distributorId: item.id.toString() })">
										{{ item.id }}
									</span>
									<span
										v-else
										class="badge badge-primary">
										{{ item.id }}
									</span>
								</td>
								<td class="align-middle">
									{{ item.attributes.username }}
								</td>
								<td class="align-middle">
									{{ item.attributes.distance }}
								</td>
								<td class="align-middle">
									{{ translate(item.attributes.highest_rank) }}
									<template v-if="subRankIndicator(item.attributes, 'highest_sub_rank') !== null">
										<img
											:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(item.attributes, 'highest_sub_rank')}_indicator.png`"
											class="ml-1 mb-2"
											alt="elite">
									</template>
									<template v-else-if="showAssociateRecognition(item.attributes.highest_rank, item.attributes.associate_recognition)">
										<img
											:src="require(`@/assets/images/themes/${themeName}/qualified/${item.attributes.associate_recognition}.png`)"
											width="20px"
											class="ml-1 mb-2"
											alt="elite">
									</template>
								</td>
								<td class="align-middle">
									{{ translate(item.attributes.rank_completed) }}
									<template v-if="subRankIndicator(item.attributes.sub_ranks_progress, 'rank_completed') !== null">
										<img
											:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(item.attributes.sub_ranks_progress, 'rank_completed')}_indicator.png`"
											class="ml-1 mb-2"
											alt="elite">
									</template>
								</td>
								<td class="align-middle">
									{{ translate(item.attributes.rank_estimated) }}
									<template v-if="subRankIndicator(item.attributes.sub_ranks_progress, 'rank_estimated') !== null">
										<img
											:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(item.attributes.sub_ranks_progress, 'rank_estimated')}_indicator.png`"
											class="ml-1 mb-2"
											alt="elite">
									</template>
								</td>
								<td class="align-middle">
									{{ translate(item.attributes.rank_progress) }}
									<template v-if="subRankIndicator(item.attributes.sub_ranks_progress, 'rank_progress') !== null">
										<img
											:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(item.attributes.sub_ranks_progress, 'rank_progress')}_indicator.png`"
											class="ml-1 mb-2"
											alt="elite">
									</template>
								</td>
								<td class="align-middle text-center">
									<b-button
										v-b-tooltip.hover
										:title="translate('see_rank_progress')"
										variant="primary"
										class="bg-primary-alt"
										@click="showModal(item.id, item.attributes.username)">
										<i
											class="fa fa-eye"
											aria-hidden="true" />
									</b-button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</b-col>
		</b-row>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
		<b-modal
			id="rankProgress"
			ref="rankProgress"
			:title="`${translate('user_id')}: ${progressId} / ${translate('username')}: ${progressUsername}`"
			:ok-title="translate('close')"
			title-tag="h5"
			header-bg-variant="dark"
			header-text-variant="light"
			size="lg"
			centered
			ok-only>
			<div v-if="progressId">
				<rank-progress
					v-if="associateQualified"
					:user-id="Number(progressId)" />
				<distributor-info-widget
					v-else
					:account-info-data="accountInfoData"
					:loading="accountInfo.data.loading" />
			</div>
		</b-modal>
	</div>
</template>

<script>
import { PAGINATION } from '@/settings/RequestReply';
import { SEARCH_USERS } from '@/config/endpoint';
import {
	Grids, Ranks, Users, Network, UnilevelRanks,
} from '@/translations';
import { admin } from '@/settings/Roles';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import Reports from '@/util/Report';
import Sponsored from '@/views/Network/mixins/Sponsored';
import RankProgress from '@/views/Dashboard/components/RankProgress';
import DistributorInfoWidget from '@/views/Dashboard/components/widgets/DistributorInfoWidget';
import DataFilter from '@/components/DataFilter';
import Dashboard from '@/util/Dashboard';
import GeneralInformation from '@/util/GeneralInformation';
import { S3_PATH } from '@/settings/Images';

const { endpoint } = SEARCH_USERS;
export default {
	name: 'UnilevelRanks',
	messages: [Grids, Ranks, Users, Network, UnilevelRanks],
	components: {
		DataFilter,
		RankProgress,
		DistributorInfoWidget,
	},
	mixins: [FiltersParams, DashboardRedirect, Sponsored],
	data() {
		return {
			reports: new Reports(),
			accountInfo: new Dashboard(),
			packages: new GeneralInformation(),
			admin,
			endpoint,
			progressId: 0,
			progressUsername: '',
			themeName: process.env.VUE_APP_THEME,
			S3_PATH,
		};
	},
	computed: {
		loading() {
			try {
				return !!this.reports.data.loading;
			} catch (error) {
				return false;
			}
		},
		pagination() {
			return this.reports.data.pagination;
		},
		data() {
			try {
				const { data } = this.reports.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		weeks() {
			try {
				const { weeks } = this.reports.data.response.data.meta;
				return weeks;
			} catch (error) {
				return {};
			}
		},
		hasData() {
			try {
				const response = this.data.length;
				return !!response;
			} catch (error) {
				return false;
			}
		},
		isAdminOrCorporate() {
			return this.admin.includes(this.$user.details().type);
		},
		distributorId() {
			let { distributorId } = this.$route.params;
			if (typeof distributorId === 'undefined') {
				distributorId = this.$user.details().id;
			}
			return distributorId;
		},
		accountInfoData() {
			try {
				const { response } = this.accountInfo.data.response.data;
				return response;
			} catch (error) {
				return {};
			}
		},
		isPackageCheck() {
			let index = this.packagesData.indexOf(this.activePackage);
			if (index < this.packagesData.length - 1 && index !== -1) {
				index += 0.70;
			} else if (index === this.packagesData.length - 1) { // last_package
				index += 1;
			} else { // no_package
				index = 0;
			}
			return index >= 0.7; // apprentice index
		},
		packagesData() {
			try {
				return this.packages.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		activePackage() {
			try {
				const found = this.packagesData.find((pack) => pack.attributes.code_name === this.accountInfoData.product);
				return found || {
					attributes: {
						code_name: 'no_package',
					},
				};
			} catch (error) {
				return {};
			}
		},
		associateQualified() {
			try {
				return this.accountInfoData.status === 'active' && this.accountInfoData.qualified && this.isPackageCheck;
			} catch (error) {
				return false;
			}
		},
	},
	mounted() {
		this.packages.getPackages();
		this.getSummary();
	},
	methods: {
		getSummary(params) {
			this.reports.getUnilevelRanks(this.distributorId, params);
		},
		showAssociateRecognition(highestRank, associateRecognition) {
			return highestRank === 'associate' && associateRecognition !== '';
		},
		getDataFiltered(page) {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			this.$router.push({ name: this.$route.name, query: options, params: { distributorId: this.distributorId } });
			this.getSummary(options);
		},
		showModal(distributorId, distributorUsername) {
			this.progressId = distributorId;
			this.progressUsername = distributorUsername;
			this.accountInfo.getAccountInfo(this.progressId);
			this.$refs.rankProgress.show();
		},
		subRankIndicator(item, subRankType) {
			try {
				const subRankSplitted = item[subRankType].split('_');
				return subRankSplitted[subRankSplitted.length - 1] || null;
			} catch (error) {
				return null;
			}
		},
	},
};
</script>
